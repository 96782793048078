.App {
    text-align: center;
  }
  
  header {
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 50px;
    padding-right: 50px;
  }

  .input-div{
    margin-top: -38px;
    margin-bottom: 30px;
    z-index: 1 !important;
    position: relative;
    margin-left: 20px;
    color: #6f7275;
  }

  .input-div-balance{
    margin-top: -130px;
    margin-bottom: 100px;
    z-index: 1 !important;
    position: relative;
    margin-left: 15px;
    color: #6f7275;
    width: 230px;
    font-size: 12px;
  }

  .img-margin-right{
    margin-right: 10px;
    font-size: 10px;
  }

  .img-margin-right-blue{
    margin-right: 5px;
    font-size: 15px;
    color: #0095eb;
  }

  .btn-light{
    --bs-btn-active-bg:  #090b13 !important;
    --bs-btn-active-color: #8f8f8f !important;
    --bs-btn-active-border-color:#090b13 !important;

  }
  
  .divOrtayaHizala {
    position: absolute;
    left:44%;
    font-size: 30px;
    color: #0095eb;
    margin-top: 25px;
    z-index: 1;
  }

  .maks-button{
    font-size: 12px;
    color: #0095eb;
  }

  .padding-sifir {
    padding: 0px !important;
  }

  .w100 {
    width: 100%;
  }
  
  .hidediv {
    display: none !important;
  }

  .routeText {
    font-size: 11px;
    text-align: left;
    padding-top: 5px;
    padding-left: 5px;
  }

  .errorMessage  {
    margin-bottom: 20px;
    margin-left: 10px;
    color: #ff7f00;
    max-width: 60%;
    text-align: center;
    align-items: center;
  }

  .marjinSifir{
    margin-left: 0px !important;
    margin-right: 0px !important;
    justify-content: center;
  }

  .logo{
    width: 70px;
    height: 70px;
    padding-right: 20px;
  }
  
  .eth{
    width: 90px;
    height: 50px;
    margin-right:-15px;
    padding-top:5px;
  }
  
  .leftH {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  
  .rightH {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
  }

  .right {
    text-align: right;
  }
  
  .headerItem {
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 5px;
    font-weight: 500;
    transition: 0.3s;
    display: flex;
    align-items: center;
  }
  
  .headerItem:hover {
    background-color: rgb(34, 42, 58);
    cursor: pointer;
  }
  
  .connectButton {
    background-color: #243056; 
    padding: 10px;
    padding-right: 20px;
    padding-left: 20px;
    border-radius: 100px;
    color: #5981F3;
    font-weight: bold;
    transition: 0.3s;
  }
  
  .connectButton:hover {
    cursor: pointer;
    color: #3b4874
  }
  
  .mainWindow {
    margin-top: 40px;
    display: flex;
    justify-content: center;
  }
  
  .link {
    text-decoration: none;
    color: white;
  }

  .subPanelColor {
    background:linear-gradient(#131313 0,#131313 100%)
  }

  .colorBlack
  {
    background: rgb(19, 19, 19);
  }
  
  @media (prefers-color-scheme:light)
  {html{background:radial-gradient(100% 100% at 50% 0,rgba(255,184,226,0) 0,rgba(255,255,255,0) 100%),#fff}}
  
  /* width: 98%; */
  .tradeBox {
    padding: 0px 8px 0px;
    margin-bottom: 50px;
    max-width: 320px;
    background: rgb(0, 0, 0);
    color: #d9d6d3;
    border: 1px solid rgba(255, 255, 255, 0.07);
    /* margin-top:0px;
    margin-right: 5px;
    margin-left: 5px; */
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* justify-content: flex-start;
    align-items: flex-start; */
    /* padding-left: 30px;
    padding-right: 30px;
    padding: 12px 8px 8px; */
    box-shadow: rgba(0, 149, 255, 0.08) 0px 0px 10px 0px, rgba(0, 149, 255, 0.18) 0px 40px 120px 0px;
    z-index: 1;
    transition: transform 250ms ease 0s;
  }
  
  .h4Tag{
    box-sizing: border-box;
    margin: 5px;
    min-width: 0px;
    font-weight: 485;
    font-size: 20px;
    line-height: 24px;
  }
  
  .tradeBoxHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 99%;
    padding-top:15px;
    padding-bottom:15px;
  }
  
  .cog {
    color: #c5d0f1;
    transition: 0.3s;
  }
  
  .cog:hover {
    color: white;
    rotate: 90deg;
    cursor: pointer;
  }
  
  .switchButton {
    background-color: #3a4157;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 8px;
    position: absolute;
    top: 100px;
    left: 42%;
    color: #5F6783;
    border: 3px solid #0E111B;
    font-size: 12px;
    transition: 0.3s
  }
  
  .dollarAmount {
    position: absolute;
    top: 75px;
    left: 14px;
    font-size: 10px;
    color: #5F6783;
  }
  
  .switchButton:hover {
    color: white;
    cursor: pointer;
  }
  
  .inputs {
    position: relative;
  }
  
  .assetOne {
    position: absolute;
    min-width: 50px;
    height: 30px;
    background-color: #3a4157;
    top: 10px;
    right: 5px;
    border-radius: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    font-weight: bold;
    font-size: 14px;
    padding-right: 8px;
  }
  
  .assetTwo {
    position: absolute;
    min-width: 50px;
    height: 30px;
    background-color: #3a4157;
    top: 133px;
    right: 5px;
    border-radius: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    font-weight: bold;
    font-size: 14px;
    padding-right: 8px;
  }
  
  .assetOne:hover{
    cursor: pointer;
  }
  
  .assetTwo:hover{
    cursor: pointer;
  }
  
  .assetLogo {
    height: 22px;
    margin-left: 5px;
  }
  
  .swapButton {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #243056; 
    width: calc(100%);
    height: 55px;
    font-size: 20px;
    border-radius: 12px;
    color: #d9d6d3;
    font-weight: bold;
    transition: 0.3s;
    margin-bottom: 30px;
    margin-top: 8px;
  }
  
  .swapButton[disabled] {
    background-color: #243056;
    opacity: 0.4;
    color: #5982f39b;
  }
  
  .swapButton[disabled]:hover {
    cursor: not-allowed;
    background-color: #243056;
  }
  
  .swapButton:hover {
    cursor: pointer;
    background-color: #3b4874
  }
  
  .tokenLogo {
    height: 40px;
    width: 40px;
  }
  
  .modalContent {
    border-top: 1px solid #363e54;
    /* padding-top: 20px; */
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px
  }

  .colorRed{
    color:red;
  }
  
  .tokenChoice {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  
  .tokenChoice:hover {
    cursor: pointer;
    background-color: #1f2639;
  }
  
  .tokenName {
    margin-left: 10px;
    font-size: 16px;
    font-weight: 500;
  }
  
  .tokenTicker {
    margin-left: 10px;
    font-size: 13px;
    font-weight: 300;
    color: #51596f;
  }

 
@media (min-width: 480px) {
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
}

@media (min-width: 320px) {
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

} 

@media screen and (max-width: 480px) {
  .wallet-min-text {
    font-size: 11px;
  }
}

@media screen and (max-width: 320px) {
  .wallet-min-text {
    font-size: 11px;
  }
}