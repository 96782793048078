
  .radius-2px {
    border-radius: 7px !important;
    border-color: #070b13 !important;
  }

  .card-no-border-color {
    --bs-card-border-color : #070b13 !important;
    background-color :#070b13 !important;
    border-color  :#070b13 !important;
  }
  
  .table > :not(caption) > * > *
  {
    border-bottom-width:0px !important;
  }
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
  
  .ant-input {
    background-color: #1f2639 !important;
    color: rgb(155, 155, 155) !important;
    border-width: 0px !important;
    height: 120px !important;
    margin-bottom: 5px;
    font-size: 31px;
    border-radius: 16px !important;
    font-weight: 500;
    line-height: 20px;
    padding: 16px;
    padding-top: 23px;
    position: relative;
    max-width:310px
  }
  
  .ant-input::placeholder {
    color: #5f6783 !important;
  }
  
  .ant-popover-arrow {
    visibility: hidden;
  }
  
  .ant-popover-title {
    color: white !important;
  }
  
  .ant-popover-inner-content {
    color: white !important;
  }
  
  .ant-popover-inner {
    min-width: 260px !important;
    min-height: 140px !important;
    border: 1px solid #21273a;
    background-color: #0e111b !important;
  }
  
  .ant-radio-group {
    margin-top: 10px;
  }
  
  .ant-radio-button-wrapper {
    background-color: #1f2639 !important;
    color: white !important;
    font-weight: 500;
    border-color: white !important;
  }
  
  .ant-radio-button-wrapper-checked {
    border-color: white !important;
    background-color: #363e54 !important;
  }
  
  .ant-radio-button-wrapper-checked::before {
    background-color: white !important;
  }
  
  .ant-modal-content {
    background-color: #0e111b !important;
    color: white !important;
    padding: 0px !important;
  }
  
  .ant-modal {
    border: 1px solid #363e54;
    width: 400px !important;
    border-radius: 10px;
    padding-bottom: 0px;
  }
  
  .ant-modal-header {
    background-color: #0e111b !important;
  }
  
  .ant-modal-title {
    color: white !important;
    padding-top: 17px !important;
    margin-left: 20px !important;
  }
  
  .ant-modal-close-icon {
    color: #363e54 !important;
    transition: 0.3s;
  }
  
  .ant-modal-close-icon:hover {
    color: white !important;
  }


  @media only screen and (max-width: 600px) {
    .ant-input {
      max-width:305px
    }

    .accordion  {
      max-width:305px
    }
  }


  @media only screen and (max-width: 768px) {
    .ant-input {
      max-width:305px
    }

    .accordion  {
      max-width:305px
    }
  }


  @media only screen and (min-width: 992px) {
    .ant-input {
      max-width:310px
    }

    .accordion  {
      max-width:350px
    }
  }
  

  @media only screen and (min-width: 1200px) {
    .ant-input {
      max-width:310px
    }

    .accordion  {
      max-width:350px
    }
  }

  .jWpMhA {
    background-color: transparent !important;
    
  }

  .hXLGuA {
    border-radius: 5px !important;
  }
 

